"use client";

import { Metadata } from "next";
import Image from "next/image";
import { usePathname } from "next/navigation";

import spaceman from "@/assets/images/spaceman.png";
import Button from "@/components/atoms/Button";
import { fallbackLng, languages } from "@/i18n/settings.ts";
import { AllowedLanguages } from "@/types/generic.ts";

export async function generateMetadata(): Promise<Metadata> {
  return {
    robots: "noindex",
  };
}

const NotFound = () => {
  const pathname = usePathname();
  const locale = pathname?.split("/").filter(Boolean)?.[0];
  const lang = languages.includes(locale as AllowedLanguages)
    ? locale
    : fallbackLng;

  const i18n = {
    nl: {
      errorCode: "404",
      title: "Lost in space",
      subtitle:
        "Oh, jammer! Deze pagina ging verloren in het World Wide Web.<br>No worries, er is nog veel te ontdekken op onze site. Go, go, go!",
      labelBtn: "Naar de startpagina",
    },
    fr: {
      errorCode: "404",
      title: "Lost in space",
      subtitle:
        "Oh, dommage ! Cette page a été perdue dans le World Wide Web.<br>Pas de souci, il reste encore beaucoup à découvrir sur notre site. Go, go, go !",
      labelBtn: "Vers la page principale",
    },
    en: {
      errorCode: "404",
      title: "Lost in space",
      subtitle:
        "Oops! This page went lost in the World Wide Web.<br>No worries, there is still a lot to discover on our site. Go, go, go!",
      labelBtn: "To the homepage",
    },
  };

  const translations = i18n[lang ?? fallbackLng] as (typeof i18n)[
    | "fr"
    | "nl"
    | "en"];

  return (
    <main className="relative grid flex-1 place-items-center self-stretch overflow-hidden bg-white px-6 py-24 sm:py-32 lg:px-8">
      <div className="relative z-1 text-center">
        <p className="text-base font-semibold text-green">
          {translations.errorCode}
        </p>
        <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">
          {translations.title}
        </h1>
        <p
          className="mt-6 text-base leading-7 text-gray-600"
          dangerouslySetInnerHTML={{ __html: translations.subtitle }}
        ></p>
        <div className="mt-10 flex items-center justify-center gap-x-6">
          <Button as="link" href={"/" + locale}>
            {translations.labelBtn}
          </Button>
        </div>
      </div>

      <Image
        src={spaceman.src}
        alt="Where am I?"
        className="absolute animate-spaceman"
        width={spaceman.width}
        height={spaceman.height}
      />
    </main>
  );
};

export default NotFound;
